import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { TRADENAME } from 'commons/js/constants'
import { Icon, Copy } from '@elparking/components'

import { Control } from 'commons/js/ui/Input'

import messages from './messages'

const TRANSITION_DURANTION = '0.3s'

export const Label = styled(Copy)`
    cursor: pointer;
`

const InputBlock = styled.div`
    overflow: hidden;
    height: ${({expanded}) => expanded ? 'auto' : '0'};
    transition: ${TRANSITION_DURANTION};
    padding: ${({expanded}) => expanded ? '2px 0' : '0'};
`

InputBlock.propTypes = {
    expanded: PropTypes.bool,
}

InputBlock.defaultProps = {
    expanded: false,
}

const ArrowIcon = styled.span`
    margin-left: 8px;
    display: inline-block;
    transform: rotate(${({expanded}) => expanded ? '0' : '-180'}deg);
    transition: ${TRANSITION_DURANTION};
`

ArrowIcon.propTypes = {
    expanded: PropTypes.bool,
}

class PromoCodeField extends React.Component {
    static propTypes = {
        intl: PropTypes.object,
        showExpanded: PropTypes.bool,
        element: PropTypes.elementType,
    }

    static defaultProps = {
        showExpanded: false,
        element: Control,
    }

    state = {
        expanded: this.props.showExpanded,
    }

    onLabelClick = this.onLabelClick.bind(this)

    onLabelClick () {
        this.setState((state) => ({
            expanded: !state.expanded,
        }))
    }

    render () {
        const { intl, element: Element, ...controlProps } = this.props
        const { expanded } = this.state
        return (
            <div>
                <Label color='main' fontWeight='book' padding='0 0 8px 0' size='xxSmall' onClick={() => this.setState({ expanded: !expanded })}>
                    {intl.formatMessage(messages.question, {
                        tradename: TRADENAME,
                    })} <ArrowIcon expanded={expanded}><Icon type='arrow-up' size='xxSmall' /></ArrowIcon>
                </Label>
                <InputBlock expanded={expanded}>
                    {expanded && <Element
                      model='.promoCode'
                      validateIcon
                      placeholder={intl.formatMessage(messages.placeholder)}
                      {...controlProps}
                                 />}
                </InputBlock>
            </div>
        )
    }
}

export default injectIntl(PromoCodeField)
