import { defineMessages } from 'react-intl'

export default defineMessages({
    question: {
        defaultMessage: '¿Tienes un código de {tradename}?',
    },
    placeholder: {
        defaultMessage: 'Introduce tu código',
    },
})
